import { ref } from '@vue/composition-api'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useTrackings() {
  const { errorToast, successToast } = useAlertNotification()
  // const { getUsersByTransporteurSociete, users } = useDiffusion()

  // const {
  //   getTransporteurTrackingForAffreteur, trackings, getTracking,
  // } = useTrackings()
  const tracking = ref([])
  const trackings = ref([])
  const loader = ref(false)
  const trackingProcess = ref(false)
  const trackingSuccess = ref(false)
  const errors = ref('')
  // Liste des trackings
  const getAllTracking = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-tracking')
      if (response.data.success === true) {
        loader.value = false
        trackings.value = response.data.data
        trackings.value = trackings.value.map(item => ({
          ...item, position: { lat: item.lat, lng: item.lng }, tooltip: `${`${item.transporteur.lastname} ${item.transporteur.firstname}`}`, visible: true,
        }))
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getTransporteurTrackingForAffreteur = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-tracking-for-affreteur')
      if (response.data.success === true) {
        loader.value = false
        trackings.value = response.data.data
        trackings.value = trackings.value.map(item => ({
          ...item, position: { lat: item.lat, lng: item.lng }, tooltip: `${`${item.transporteur.lastname} ${item.transporteur.firstname}`}`, visible: true,
        }))
      }
    } catch (error) {
      loader.value = false
    }
  }
  // eslint-disable-next-line consistent-return
  const getUserPosition = () => {
    if (navigator.geolocation) {
      // get GPS position
      // const position = await navigator.geolocation.getCurrentPosition()
      return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
    }
  }
  // Obtenir une tracking
  const getTracking = async transporteurId => {
    try {
      const response = await axiosClient.get(`/get-user-tracking/${transporteurId}`)
      tracking.value = response.data.data
      trackings.value = []
      trackings.value.push(response.data.data)
      trackings.value = trackings.value.map(item => ({
        ...item, position: { lat: item.lat, lng: item.lng }, tooltip: `${`${item.transporteur.lastname} ${item.transporteur.firstname}`}`, visible: true,
      }))
    } catch (error) {
      console.clear()
      const position = await getUserPosition()
      const { coords } = position
      tracking.value = []

      trackings.value = []
      const data = { id: 1 }
      trackings.value.push(data)
      trackings.value = trackings.value.map(item => ({
        ...item, lat: coords.latitude, lng: coords.longitude, position: { lat: coords.latitude, lng: coords.longitude }, tooltip: 'Pas de camions en route', visible: true,
      }))
    }
  }
  // Ajouter une tracking
  const createOrUpdateTracking = async data => {
    try {
      errors.value = ''
      trackingProcess.value = true
      const response = await axiosClient.post('/store-tracking', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        trackingSuccess.value = true
        trackingProcess.value = false
      }
    } catch (error) {
      trackingProcess.value = false
      trackingSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const createUserErrors = error.response.data.errors
      Object.keys(createUserErrors).forEach(key => {
        errorToast('Oups! Erreur', createUserErrors[key][0])
      })
    }
  }

  return {
    errors,
    tracking,
    trackings,
    getAllTracking,
    trackingProcess,
    getTransporteurTrackingForAffreteur,
    createOrUpdateTracking,
    getTracking,
    loader,
    getUserPosition,
    trackingSuccess,
  }
}
