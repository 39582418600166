export default [
  {
    title: 'Tableau de bord',
    route: 'espace-societe.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Gestion des Offres',
    icon: 'PackageIcon',
  },
  {
    title: 'Disponible',
    icon: 'TruckIcon',
    route: 'espace-societe.disponible',
  },
  {
    header: 'Gestion des devis',
    icon: 'PackageIcon',
  },
  {
    title: 'Etat des devis',
    icon: 'ListIcon',
    children: [
      {
        title: 'Devis soumis',
        color: 'primary',
        icon: 'DiscIcon',

        route: 'espace-societe.devis-soumis',

      },
      {
        title: 'Devis acceptés',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-societe.devis-accepte',

      },
      {
        title: 'Devis rejetés',
        color: 'danger',
        icon: 'DiscIcon',

        route: 'espace-societe.devis-rejete',

      },
      {
        title: 'Devis suspendus',
        route: 'espace-societe.devis-suspendu',
        icon: 'DiscIcon',

        color: 'warning',
      },
    ],
  },
  {
    header: 'Gestion des demandes d\'expertise',
    icon: 'PackageIcon',
  },
  {
    title: 'Mes demandes d\'expertise',
    icon: 'ListIcon',
    children: [
      {
        title: 'Poser une question',
        color: 'primary',
        icon: 'DiscIcon',

        route: 'espace-societe.demande-expertise',

      },
      {
        title: 'Mes demandes',
        color: 'info',
        icon: 'DiscIcon',

        route: 'espace-societe.expertises-soumises',

      },
      {
        title: 'Expertises traitées',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-societe.expertises-traitees',

      },

    ],
  },
  {
    header: 'Facturation',
    icon: 'PackageIcon',

  },
  {
    title: 'Liste des factures',
    icon: 'ListIcon',
    route: 'espace-societe.liste-facture',

  },
  {
    header: 'Moyens de transport',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter',
    icon: 'PlusIcon',
    route: 'espace-societe.ajouter',
  },
  {
    title: 'Liste',
    icon: 'ListIcon',
    route: 'espace-societe.moyen-de-transport',

  },
  {
    header: 'Transport',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter societe',
    icon: 'PlusIcon',
    route: 'espace-societe.societes.ajouter',

  },
  {
    title: 'Liste des societes',
    icon: 'ListIcon',
    route: 'espace-societe.societes',

  },
  {
    header: 'Offre de transport',
    icon: 'PackageIcon',
  },
  {
    title: 'Ajouter disponibilité',
    icon: 'PlusIcon',
    route: 'espace-societe.ajout-disponibilite',
  },
  {
    title: 'Mes disponibilités',
    icon: 'ListIcon',
    route: 'espace-societe.disponilities',
  },
  {
    header: 'Gestion du tracking',
    icon: 'PackageIcon',
  },
  {
    title: 'Suivi de la cargaison',
    icon: 'MapPinIcon',
    route: 'espace-societe.tracking',
  },
  {
    header: 'Gestion de profil',
    icon: 'PackageIcon',
  },
  {
    title: 'Activation de compte',
    icon: 'CheckCircleIcon',
    color: 'success',
    route: 'espace-societe.activate-account',
  },
]
